import { PageTemplate } from './PageTemplate'
import { SettingUserComponent } from '../components/AppMenuBar/SettingUserComponent'

const ShowSettingUserPage = () => {
    return (
        <PageTemplate
            type="customer" headerPage="usersetting" headerElement="header"
            leftPaneComponent={null} mainComponent={SettingUserComponent()} buttonComponent={null} >
        </PageTemplate>
    );
}
export { ShowSettingUserPage }