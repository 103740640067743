import { createSignal, createMemo, createResource, For, Show, Suspense } from "solid-js";
import { createStore, } from "solid-js/store";
import { SelectChangeEvent } from "@suid/material/Select";
import { ListItemButton, ListItem, FormControlLabel, Switch, Chip, TextField, FormControl, InputLabel, Select, Menu, MenuItem, ListItemText, ToggleButtonGroup, ToggleButton, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, Typography, Button, IconButton, Stack, Card, Alert, Popover, Grid, Divider, Box } from "@suid/material";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from "@suid/material"

import { Tabs, Tab } from '../SmallComponents/SmallTabs';
import { ThemeSwitcher } from "./AppBarToogleColorMode"
import { AvatarWithWfButtons, } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList'

import { PhoneNumberInput } from "../SmallComponents/SmallFieldPhoneNumber";

import { getMobilePrefixFromCountryCode } from "../DataLists/DataCountryList";
import { FieldPassword } from "../SmallComponents/SmallFieldPassword";
import { CloseListIcon, CloseIcon, EditIcon, DeleteIcon, LockIcon } from '../icons';
 
import { useNavigate } from "@solidjs/router";
import { AccountStatusChip } from '../SmallComponents/SmallChips';
import { PageListMenu } from '../SmallComponents/SmallListMenu';

import { FieldTextValue } from "../SmallComponents/SmallFieldText";
import { CountrySelect } from '../SmallComponents/SmallFieldCountryCodeList'

import { isExtraSmall, isExtraLarge } from "../../../utils/sizeUtil"
import { formatDateByCountryLong, formatDateByCountry , formatTimeByCountry, isInvoiceDueDatePassed} from "../../../utils/datetimeflag"

import { FieldSelectList } from '../SmallComponents/SmallFieldSelectList'
import { getText, GetTextUI, SelectTranslation, SelectLanguageMenuItem } from '../SmallComponents/Translate'

import { UserFetchType } from "../../../types/userDataTypes"

interface DialogHeaderProps {
    header: string;
}

const apiUrl = import.meta.env.VITE_APP_API_URL;

const countryCode = "DK"

const SettingUserComponent = () => {

    const [activeTab, setActiveTab] = createSignal<number>(0)
    const checkSize = isExtraLarge()

    const [open, setOpen] = createSignal(false);
    const handleClose = () => setOpen(false);

    const [state, setState] = createStore({
        userEmail: '',
        userFirstName: '',
        userLastName: '',
        userMobilePrefix: '',
        userMobile: '',
        userLanguage: '',
        userCountry: '',
        customerWWW: 'www.',
        customerAvatar: 'http://',
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);

    };

    const [userPassword, setUserPassword] = createSignal('');
    const [userPassword2, setUserPassword2] = createSignal('');
    const [pwMessage, setPwMessage] = createSignal('');
    //const { userStore } = useUserContext() as { userStore: UserStore };
    //const { setUserStore } = useUserContext() as { setUserStore: SetStoreFunction<UserStore> };
    //const { userStore, setUserStore }  = useUserContext();
    const txtNoMatch = getText("confirmpassword", "nomatch")
    let checkMatch =  () => {
        (userPassword() === userPassword2()) ? setPwMessage('') : setPwMessage(txtNoMatch() as string);
    }
    const handleChangePassword = (pw: string) => {
        setUserPassword(pw)
        checkMatch()
    }
    const handleChangePassword2 = (pw: string) => {
        setUserPassword2(pw)
        checkMatch()
    }


    const fetchUser = async (): Promise<UserFetchType> => {
        const response = await fetch(`${apiUrl}/security/user`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('User response was not ok.');
        }
        const data = await response.json();
        return data;
    }


    const [user] = createResource<UserFetchType>(() => fetchUser());

    const navigate = useNavigate();
    const handleNavigateRoute = (name: string) => {
        navigate(name);
    }


    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const handleOpenPopover = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const [anchorMenuEl, setAnchorMenuEl] = createSignal<Element | null>(null);
    const openMenu = () => Boolean(anchorMenuEl());
    const handleMenuOpen = (event: { currentTarget: Element }) => {
        setAnchorMenuEl(event.currentTarget);
    }
    const handleMenuClose = () => {
        setAnchorMenuEl(null);
    };



    const [openAddJobDialog, setOpenAddJobDialog] = createSignal(false);
    const handleOpenAddJobDialog = () => {
        setOpenAddJobDialog(true);
    }
    const handleCloseAddJobDialog = () => {
        setOpenAddJobDialog(false);
    }
    const handleAddJob = () => {

    }

    const [handoverEnabled, setHandoverEnabled] = createSignal(false);
    const [suspendEnabled, setSuspendEnabled] = createSignal(false);

    const txtClose = getText("default", "close")
    const txtAdd = getText("smallcomponent", "addtag")

    const [size, setSize] = createSignal({ dialogMargin: 2, dialogSpacing: 2, buttonSize: 200 })
    if (isExtraSmall())
        setSize({ dialogMargin: 1, dialogSpacing: 1, buttonSize: 40 });

    const DialogHeader = ({ header }: DialogHeaderProps) => {
        return (
            <DialogTitle>
                <Stack direction="row" spacing={size().dialogSpacing} >
                    <IconButton onClick={handleCloseAddJobDialog} > <CloseIcon /> </IconButton>
                    <Typography variant="h4">{header}</Typography>
                </Stack>
            </DialogTitle>
        )
    }
    interface footerProps {
        showUpdateButton: boolean;
    }
    const DialogFooter = (props: footerProps) => {
        return (
            <DialogActions>

                <Button variant="contained" color="info" onClick={handleAddJob}>{txtAdd()}</Button>

                <Button variant="outlined" color="info" onClick={handleCloseAddJobDialog}>{txtClose()}</Button>
            </DialogActions>
        )
    }
    /*
    const ShowMenu = () => {
        return (
            <>
                <ListItemButton >
                    <ListItem >
                        <ListItemText> <GetTextUI formName={"usersetting"} label={"askforadm"} /></ListItemText>
                        </ListItem>
                </ListItemButton>
                        <ListItemButton >
                    <ListItem >
                         <ListItemText><GetTextUI formName={"usersetting"} label={"askforbilling"} /> </ListItemText>
                         </ListItem>
                </ListItemButton>
                         <ListItemButton >
                    <ListItem >
                        <ListItemText> <GetTextUI formName={"usersetting"} label={"askforcontract"} /></ListItemText>
                        </ListItem>
                </ListItemButton>
                        <ListItemButton >
                    <ListItem >
                        <ListItemText> <GetTextUI formName={"usersetting"} label={"askforretire"} /></ListItemText>
                    </ListItem>
                </ListItemButton>
            </>
        )
    }*/

    const txtAccountVerified = getText("candidatepage", "accountverified")
    const txtAccountNotVerified = getText("candidatepage", "accountnotverified")

    const txtLanguages = getText("default", "languages")
    const txtCountry = getText("quicklauncher", "country")


    const txtPassword = getText("contact", "password")
    const txtReenterPassword = getText("confirmpassword", "reenterpassword")
    const txtMobile = getText("contact", "mobilenumber")
    const txtFirstname = getText("contact", "firstname")
    const txtLastname = getText("contact", "lastname")
    const txtSubmit = getText("confirmpassword", "submit")

    const txtSystemSettings = getText("usersetting", "systemsettings")
    const txtDangerousZone = getText("usersetting", "dangerouszone")
    const txtDangerousZoneConfirm = getText("usersetting", "dangerouszoneconfirm")
    const txtDangerousZoneConfirmHandover = getText("usersetting", "dangerouszoneconfirmhandover")

    const txtTabDetails = getText("onboard", "tabdetails")
    const txtTabSecurity = getText("usersetting", "security")
    const txtTabUsers = getText("companysetting", "users")
    const txtAccountInformation = getText("usersetting", "accountinformation")
    const txtAccessAs = getText("usersetting", "accessas")
    const txtChangePassword = getText("usersetting", "changepassword")
    const txtMemberOfTeams = getText("usersetting", "memberofteams")
    const txtResentLogin = getText("usersetting", "resentlogin")

    // ****************************************************************
    // REMEMBER THIS: cv()?.candidate?.[0]?.verified ******************
    // ****************************************************************
    // {cv()?.candidate?.[0]?.src}
    // {/* ? cv()?.candidate?.[0]?.src as string : "/assets/imgs/avatar2.jpg" */}


    const tabDetailsId = 0, tabSecurityId = 1, tabUsersId = 2, tabDangerousZoneId = 3;


    return (
        <>
            {/* Present Candidate Profile section for Customer */}

            <Card sx={{ margin: 1 }}>

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>
                    <For each={user()?.user}>
                        {(item, idx) => (
                            <>
                                <Grid container   >

                                    <Grid item xs={12} sm={8} md={9} lg={8} xl={6}>
                                        <Stack margin={1}>
                                            <Tabs activeTab={activeTab()} setActiveTab={setActiveTab}>
                                                <Tab>{txtTabDetails()} </Tab>
                                                <Tab>{txtTabSecurity()}  </Tab>
                                                <Tab>{txtMemberOfTeams()}  </Tab>
                                                <Tab>{txtDangerousZone()}  </Tab>
                                            </Tabs>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={3} lg={4} xl={6} >
                                        <Stack direction="row" justifyContent="right" alignContent="center" margin={1} spacing={1}>
                                            {/* Present Candidates account status */}
                                            <AccountStatusChip >{item.verified ? txtAccountVerified() : txtAccountNotVerified() as string}</AccountStatusChip>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                <Popover
                                    id="mouse-over-popover" sx={{ pointerEvents: "none" }}
                                    open={openPopover()}
                                    anchorEl={anchorEl()}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
                                    transformOrigin={{ vertical: "top", horizontal: "left", }}
                                    onClose={handleClosePopover}
                                    disableRestoreFocus
                                >
                                    <Typography color="text.primary">
                                        <GetTextUI formName={"usersetting"} label={"editemail"} />
                                    </Typography>
                                </Popover>

                                <Show when={activeTab() === tabDetailsId}>
                                    <Box sx={{ p: 2, display: 'flex' }}>

                                        <Grid container spacing={2}>



                                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                <AvatarWithWfButtons src={item?.src} />
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={4} lg={5} xl={5}>

                                                <Stack spacing={2}>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography variant="h4">{item.email}</Typography>
                                                        <IconButton size="small" onMouseEnter={event => handleOpenPopover(event)} onMouseLeave={handleClosePopover} onClick={() => handleNavigateRoute("/candidatecvedit")}>
                                                            <EditIcon aria-haspopup="true" />
                                                        </IconButton>
                                                    </Stack>
                                                    <FieldTextValue
                                                        id="firstName" label={txtFirstname()} textValue={item.firstName as string} width={230}
                                                        valueOnChange={value => setState("userFirstName", value)}
                                                    />
                                                    <FieldTextValue
                                                        id="lastName" label={txtLastname()} textValue={item.lastName as string} width={230}
                                                        valueOnChange={value => setState("userLastName", value)}
                                                    />
                                                    <Stack direction="row" spacing={2}>
                                                        <CountrySelect defaultValue={item.countryCode2Chars as string} id="contryId" label={txtCountry()} type="Country" valueOnChange={value => setState("userCountry", value)} />

                                                        <CountrySelect defaultValue={item.languageCode2Chars as string} id="languageId" label={txtLanguages()} type="Language" valueOnChange={value => setState("userLanguage", value)} />
                                                    </Stack>
                                                    <PhoneNumberInput id="mobileNoId" label={txtMobile()}
                                                        startPrefix={item.mobilePreFix ? item.mobilePreFix : getMobilePrefixFromCountryCode(item.countryCode2Chars as string)}
                                                        startValue={item.mobileNumber as string}
                                                        prefixOnChange={value => setState("userMobilePrefix", value)}
                                                        valueOnChange={value => setState("userMobile", value)} />




                                                    <Stack direction="row" spacing={2}>
                                                        <Button variant="outlined" type="button" >  <GetTextUI formName={"usersetting"} label={"generateavatar"} /> </Button>
                                                        <Button variant="outlined" type="button" > <GetTextUI formName={"usersetting"} label={"uploadpicture"} /></Button>
                                                        <Button variant="contained" type="submit" > <GetTextUI formName={"default"} label={"update"} /> </Button>

                                                   
                                                    </Stack>


                                                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                                                            <GetTextUI formName={"usersetting"} label={"updatecv"} />
                                                            <Button onClick={() => handleNavigateRoute("/mycv")} type="button" ><GetTextUI formName={"usersetting"} label={"viewcv"} /> </Button>
                                                        </Alert>


                                                </Stack>

                                            </Grid>

                                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>

                                                <CardWithMinimize header={txtAccountInformation() as string} type="stack" defaultOpen={true}>
                                                    <Stack margin={2} spacing={2}>
                                                        <Chip variant="outlined" label={txtAccessAs()} />
                                                        <Typography variant="body2" color="text.secondary"><GetTextUI formName={"usersetting"} label={"roleexplanation"} /></Typography>
                                                        <Button variant="outlined" type="button"><GetTextUI formName={"usersetting"} label={"askforextendedaccess"} /></Button>
                                                    </Stack>
                                                </CardWithMinimize>

                                                <CardWithMinimize header={txtSystemSettings() as string} type="stack" defaultOpen={true}>
                                                    <Stack margin={2} spacing={2} direction="column">

                                                        <Typography variant="body2" color="text.secondary"> <GetTextUI formName={"usersetting"} label={"preferedlanguage"} /> </Typography>

                                                        <SelectLanguageMenuItem />

                                                        <Typography variant="body2" color="text.secondary"> <GetTextUI formName={"usersetting"} label={"prefereddate"} /></Typography>
                                                        <Typography variant="body2" color="text.secondary"> <GetTextUI formName={"usersetting"} label={"preferedtime"} /></Typography>
                                                        <Typography variant="body2" color="text.secondary"> <GetTextUI formName={"usersetting"} label={"preferedcurrency"} /></Typography>

                                                        <Typography variant="body2" color="text.secondary"> <GetTextUI formName={"usersetting"} label={"preferedtheme"} /></Typography>
                                                        <ThemeSwitcher />

                                                    </Stack>
                                                </CardWithMinimize>


                                            </Grid>

                                        </Grid>

                                    </Box>



                                </Show>


                            </>
                        )}
                    </For>
                    <Divider />

                    <Grid container   >
                        <Show when={activeTab() === tabSecurityId}>

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                                <CardWithMinimize header={txtChangePassword() as string} type="stack" defaultOpen={true}>
                                    <Stack margin={1} spacing={1} alignItems="center" >
                                        <Stack direction={"column"} spacing={1}>
                                            <FieldPassword
                                                id="pw1" label={txtPassword() as string} textValue=""
                                                valueOnChange={value => handleChangePassword(value)}
                                            />
                                            <FieldPassword
                                                id="pw2" label={txtReenterPassword() as string} textValue=""
                                                valueOnChange={value => handleChangePassword2(value)}
                                                helperText={pwMessage()}
                                                error={userPassword() !== userPassword2()}
                                            />
                                        </Stack>
                                        <Button variant="outlined" startIcon={<LockIcon />} type="submit">{txtSubmit() as string} </Button>
                                    </Stack>
                                </CardWithMinimize>
                            </Grid>


                        </Show>

                        <Show when={activeTab() === tabSecurityId}>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                                <CardWithMinimize header={txtResentLogin() as string} type="stack" defaultOpen={true}>
                                    <Suspense fallback={
                                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                                            <GetTextUI formName={"default"} label={"loading"} />.
                                        </Alert>
                                    }>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"resentlogintime"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"resentloginplace"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"resentlogindevice"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"resentloginbrowser"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"useraction"} /></Typography></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                <For each={user()?.logins}>
                                                    {(item, idx) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell> <Typography variant="body2" color="text.secondary"> {formatDateByCountry(item?.timeStamp)} {formatTimeByCountry(item?.timeStamp)}</Typography></TableCell>
                                                                <TableCell> <Typography variant="body2" color="text.secondary">{item.location}</Typography></TableCell>
                                                                <TableCell> <Typography variant="body2" color="text.secondary">{item.brand}</Typography></TableCell>
                                                                <TableCell> <Typography variant="body2" color="text.secondary">{item.browser}</Typography></TableCell>
                                                                <TableCell> <Typography variant="body2" color="text.secondary">{item.action}</Typography></TableCell>
                                                            </TableRow>
                                                        </>
                                                    )}
                                                </For>
                                            </TableBody>
                                        </Table>
                                    </Suspense>
                                </CardWithMinimize>

                            </Grid>
                        </Show>
                    </Grid>


                    <Show when={activeTab() === tabUsersId}>
                        <Grid container   >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <CardWithMinimize header={txtMemberOfTeams() as string} type="stack" defaultOpen={true}>

                                    {/* Candidates current assignment for the customer */}
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"onboard"} label={"organization"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"userstatus"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"userrole"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"useraction"} /></Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <For each={user()?.teams}>
                                                {(item, idx) => (
                                                    <>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                                    <Avatar src={item.src}></Avatar>
                                                                    <Typography variant="body2" color="text.secondary">{item.companyName}</Typography>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell><Typography variant="body2" color="text.secondary">{item.teamUserStatus}</Typography></TableCell>
                                                            <TableCell><Typography variant="body2" color="text.secondary">{item.teamUserRole}</Typography></TableCell>
                                                            <TableCell>

                                                                <Button
                                                                    color="inherit"
                                                                    size="small"
                                                                    variant="text"
                                                                    startIcon={<CloseListIcon />}
                                                                    aria-controls={anchorMenuEl() ? "long-menu-country1" : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={anchorMenuEl() ? "true" : undefined}
                                                                    onClick={(event) => handleMenuOpen(event)}>

                                                                    <Menu
                                                                        id="long-menu-country1"
                                                                        anchorEl={anchorMenuEl()}

                                                                        open={Boolean(anchorMenuEl())}
                                                                        onClose={handleMenuClose}
                                                                    >
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantadm"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantbilling"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantcontract"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantrecruiter"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantmanager"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantuser"} /></MenuItem>
                                                                        <Divider />
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"askforretire"} /></MenuItem>
                                                                    </Menu>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )}
                                            </For>
                                        </TableBody>
                                    </Table>



                                </CardWithMinimize>
                            </Grid>
                        </Grid>
                    </Show>

                    <Show when={activeTab() === tabDangerousZoneId}>
                        <Grid container   >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CardWithMinimize header={txtDangerousZone() as string} type="stack" defaultOpen={true}>
                                    <Stack margin={2} spacing={2}>
                                        <Alert sx={{ px: 1, py: 1 }} severity="error">
                                            <Stack margin={2} spacing={2}>

                                                <Typography variant="body2" color="text.secondary"><GetTextUI formName={"usersetting"} label={"dangerouszonehandover"} /></Typography>

                                                <FormControlLabel
                                                    control={<Switch
                                                        onChange={(event, value) => {
                                                            setHandoverEnabled(value);
                                                        }}
                                                    />}
                                                    label={txtDangerousZoneConfirmHandover()}
                                                />

                                                <Button disabled={!handoverEnabled()} color="error" startIcon={<DeleteIcon />} type="submit"><GetTextUI formName={"usersetting"} label={"transferaccount"} /> </Button>
                                            </Stack>
                                        </Alert>

                                        <Alert sx={{ px: 1, py: 1 }} severity="error">
                                            <Stack margin={2} spacing={2}>

                                                <Typography variant="body2" color="text.secondary"><GetTextUI formName={"usersetting"} label={"dangerouszoneledger"} /></Typography>

                                                <FormControlLabel
                                                    control={<Switch onChange={(event, value) => {
                                                        setSuspendEnabled(value);
                                                    }}
                                                    />}
                                                    label={txtDangerousZoneConfirm()}
                                                />

                                                <Button disabled={!suspendEnabled()} color="error" startIcon={<DeleteIcon />} type="submit"><GetTextUI formName={"usersetting"} label={"suspendaccount"} /> </Button>
                                            </Stack>
                                        </Alert>
                                    </Stack>
                                </CardWithMinimize>
                            </Grid>

                        </Grid>
                    </Show>
                </Suspense>
            </Card >
            <br />
            <br />
        </>
    );


}
export { SettingUserComponent };